.dashboard-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .clients-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .clients-table th,
  .clients-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .clients-table th {
    background-color: #007bff;
    color: white;
    font-weight: bold;
  }
  
  .clients-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .clients-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .clients-table td {
    white-space: nowrap;
  }